import { Spinner, Link, MDPreview } from "components/Elements";
import { formatDate } from "utils/format";

import { useChanges } from "../api/getChanges";

export const ChangeLog = () => {
  const changesQuery = useChanges();

  if (changesQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!changesQuery.data) return null;

  return (
    <ol class="relative border-l border-gray-300 dark:border-gray-700">
      {changesQuery.data.slice(0, 4).map((change, index) => (
        <li class="mb-2 ml-4" key={index}>
          <div class="absolute w-2 h-2 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time class="mb-1 text-xs font-normal leading-none text-gray-400 dark:text-gray-500">
            {formatDate(change.createdAt)}
          </time>
          <Link to={`./changes/${change._id}`}>
            <h3 class="text-xs font-semibold text-gray-900 dark:text-white">
              {change.title}
            </h3>
          </Link>
        </li>
      ))}
    </ol>
  );
};
