import { Spinner } from "components/Elements/Spinner";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";

const variants = {
  primary:
    "bg-blue-600 dark:bg-blue-900 text-white hover:bg-gray-50:text-blue-600",
  inverse: "bg-white text-blue-600 hover:bg-blue-600:text-white",
  danger: "bg-red-600 text-white hover:bg-red-50:text-red-600",
  create: "bg-green-400 text-white hover:bg-green-600",
};

const sizes = {
  sm: "py-2 px-4 text-sm",
  md: "py-2 px-6 text-md",
  lg: "py-3 px-8 text-lg",
};

export const Button = React.forwardRef(
  (
    {
      type = "button",
      className = "",
      variant = "primary",
      size = "md",
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          "flex justify-center items-center border border-gray-300 dark:border-none disabled:opacity-70 disabled:cursor-not-allowed rounded-full shadow-sm font-medium focus:outline-none",
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = "Button";

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "inverse", "danger"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  isLoading: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};
