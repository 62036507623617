import { Spinner } from "components/Elements";
import { ContentLayout } from "components/Layout";
import {
  ArrowCircleDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ViewListIcon } from "@heroicons/react/solid";
import greetingTime from "greeting-time";
import { useEffect, useState } from "react";
import { Bookmark, File, FileText, Folder, List, Users } from "react-feather";
import { Link } from "react-router-dom";

import { useDashboardAnalytics } from "../api/getAnalytics";
import { Card } from "../components/Card";
import { ChangeLog } from "../components/ChangeLog";
import { useAuth } from "lib/auth";

export const Dashboard = () => {
  const analyticsQuery = useDashboardAnalytics();

  const { user } = useAuth();

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    setGreeting(greetingTime(new Date()));
  }, []);

  if (analyticsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  console.log(analyticsQuery.data);

  if (!analyticsQuery.data) return null;

  return (
    <ContentLayout title="Dashboard">
      {/* <Breadcrumbs /> */}
      <div className="mt-6">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <p className="text-gray-500 text-lg font-bold">
              {" "}
              {greeting} 👋🏼 {user.name}
            </p>
            <div className="grid grid-cols-4 gap-4 mt-5">
              <Card
                link="./ads"
                icon={<Bookmark className="text-gray-600" />}
                content={analyticsQuery.data.ads}
                label="Adverts"
              />
              <Card
                link="./applications"
                icon={<ViewListIcon className="h-5 text-gray-600" />}
                content={analyticsQuery.data.applications}
                label="Applications"
              />
              <Card
                link="./careers"
                icon={<Folder className="text-gray-600" />}
                content={analyticsQuery.data.jobs}
                label="Job Openings"
              />
              {/* <Card
                link="./milestones"
                icon={<Bookmark className="text-gray-600" />}
                content={analyticsQuery.data.milestones}
                label="Milestones"
              /> */}
              <Card
                link="./notices"
                icon={<List className="text-gray-600" />}
                content={analyticsQuery.data.notices}
                label="Notices"
              />
              <Card
                link="./blog"
                icon={<FileText className="text-gray-600" />}
                content={analyticsQuery.data.posts}
                label="Blog Posts"
              />
              <div className="bg-white rounded-lg shadow-md p-4">
                <Link to="./resources">
                  <File className="text-gray-600" />
                  <div className="flex flex-row items-center space-x-2">
                    <div className="font-bold text-2xl text-gray-800">
                      {analyticsQuery.data.resources}
                    </div>
                    <div className="flex flex-row space-x-1 items-center">
                      <ArrowCircleDownIcon className="h-4 text-red-600" />
                      <span
                        className="text-gray-500 text-xs font-bold"
                        style={{
                          fontSize: "0.65rem",
                        }}
                      >
                        {analyticsQuery.data.downloads} downloads
                      </span>
                    </div>
                  </div>
                  <span className="text-black text-xs font-bold">
                    Resources
                  </span>
                </Link>
              </div>
              <Card
                link="./users"
                icon={<Users className="text-gray-600" />}
                content={analyticsQuery.data.users}
                label="Users"
              />
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex flex-row justify-between items-center">
              <span className="text-gray-500">Changelog</span>
              <Link to="./changes">
                <InformationCircleIcon className="h-5 text-gray-600" />
              </Link>
            </div>
            <div className="bg-white shadow p-4 rounded-md mt-5">
              <ChangeLog />
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
