import clsx from "clsx";
import { Tooltip } from "flowbite-react";

export const FieldWrapper = (props) => {
  const { label, className, error, children } = props;
  return (
    <div>
      <label
        className={clsx(
          `block text-sm font-medium text-gray-700 dark:text-gray-400 `,
          className
        )}
      >
        <span className="ml-4">{label}</span>
        {error?.message ? (
          <Tooltip content={error?.message && error.message}>
            <div className="mt-1">{children}</div>
          </Tooltip>
        ) : (
          <div className="mt-1">{children}</div>
        )}
      </label>
    </div>
  );
};
