import { axios } from "lib/axios";
import { useQuery } from "react-query";

export const getChanges = () => {
  return axios.get("/changes");
};

export const useChanges = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["changes"],
    queryFn: () => getChanges(),
  });
};
